<template>
  <div>
    <div class="content">
      <div class="serve">
        <h2 style="text-align: center">模鼎全栈服务</h2>
        <div style="height: 70px"></div>
        <div class="advantage" style="height: 730px">
          <div class="item" v-for="item, index in serve_info" :key="index">
            <div class="icon">
              <img :src="item.img_url" alt="" width="40px"> 
            </div>
            <h1>{{item.title}}</h1><br>
            <span>{{item.desc}}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="maintenance">
      <div style="height: 50px"></div>
      <h2 style="text-align: center">模鼎运维支持</h2>
      <div class="content">
        <div class="item" v-for="item, index in maintenance_info" :key="index">
          <div class="icon">
            <img :src="item.img_url" alt="" width="40px"> 
          </div>
          <h1>{{item.title}}</h1><br>
          <span>{{item.desc}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serve_info: [
        { title: '更高效的服务', img_url: require("@/assets/images/support/gaoxiao.png"), desc: '协同产品、交付及服务团队，解决您在产品使用中遇到的问题。' },
        { title: '更专业的服务', img_url: require("@/assets/images/support/zhuanye.png"), desc: '交付团队具备丰富的项目落地经验。' },
        { title: '更具有价值的服务', img_url: require("@/assets/images/support/jiazhi.png"), desc: '以客户需求为中心，面向客户痛点场景持续优化、迭代产品功能。' },
        { title: '更全面的服务', img_url: require("@/assets/images/support/quanmian.png"), desc: '以客户成功为导向，以数字化赋能企业运营，帮助客户成功。' },
        { title: '定制化服务', img_url: require("@/assets/images/support/dingzhihua.png"), desc: '如果您有特殊需求或定制化的要求，我们可以为您提供相应的解决方案和服务，以满足您的个性化需求。' },
      ],
      maintenance_info: [
        {
          title: '故障诊断服务',
          img_url: require("@/assets/images/support/gzzd.png"),
          desc: '针对您提交的问题进行故障原因诊断，技术人员提出排除故障的措施和维护建议，必要时可通过远程技术支持来帮助您排除故障，事后有专人对问题解决过程进行电话跟踪回访。'
        },
        {
          title: '应用指导服务',
          img_url: require("@/assets/images/support/yyzd.png"),
          desc: '经过实施培训并获得认证，如仍然需要相应指导，技术人员可针对产品基本功能的使用和初级流程的设置对您进行远程指导。'
        },
        {
          title: '系统迁移服务',
          img_url: require("@/assets/images/support/xtqy.png"),
          desc: '当对服务器进行硬件维护或更换时，技术人员协助您对系统产品进行迁移，同时保证数据的完整性。'
        },
        {
          title: '补丁升级服务',
          img_url: require("@/assets/images/support/bdsj.png"),
          desc: '针对同版本内所有用户提出的优化内容，技术人员定期整合优化升级包通过远程支持供您升级，完善和提升产品功能。'
        },
        {
          title: '安装指导服务',
          img_url: require("@/assets/images/support/azzd.png"),
          desc: '当对服务器操作系统重装或升级时，技术人员针对产品安装以及系统环境配置对您进行指导。'
        },
        {
          title: '技术研讨参会资质',
          img_url: require("@/assets/images/support/jsyt.png"),
          desc: '不定期的组织企业系统应用交流会，为您提供客户产品高级应用技巧案例及典型企业整体应用经验分享。'
        },
         {
          title: '数据安全服务',
          img_url: require("@/assets/images/support/sjaq.png"),
          desc: '客服人员定期通过远程检测数据库备份策略运行情况，并协助您进行数据备份或还原服务。'
        },
         {
          title: '上门指导服务',
          img_url: require("@/assets/images/support/smzd.png"),
          desc: '服务期内，如遇远程无法解决的问题时，我们将安排服务人员上门进行指导。'
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
    createAnimation() {
      let serve = document.querySelector('.serve')
      // serve.style.transform = 'translateY(50px)'
      serve.style.transition = '2s'
    }
  }
}
</script>

<style lang="less" scoped>
.advantage .item .icon, .maintenance .item .icon {
  width: 60px;
  height: 100%;
  float: left;
}
.serve {
  animation: serve 2s forwards;
}
@keyframes serve {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.maintenance {
  width: 100%;
  height: 1460px;
  background: #f6f6f6;
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 500px;
      height: 260px;
      box-sizing: border-box;
      padding: 20px;
      border: 2px solid white;
      border-radius: 20px;
      margin-top: 40px;
      background: white;
      span {
        font-size: 18px;
        line-height: 36px;
        letter-spacing: 1px;
        color: #828181;
      }
    }
  }
}
</style>